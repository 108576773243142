import * as React from "react";
import process from "process";

import { Box, Center, OrderedList, ListItem, Heading } from "@chakra-ui/react";
import keccak256 from "keccak256";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Social from "../components/social";
import { WAITLIST_MERKLE_TREE, TEAM_MERKLE_TREE, ALLOWLIST_MERKLE_TREE } from "../freemint-config";

import freeMintGif from "../images/DEATH_GIRL_FREE_MINT_WEB_NO_LOGO.gif";

import "@fontsource/vt323";

if (typeof window !== "undefined") {
    // global.window = {}
    window.process = process;
}

const LicensePage = () => {
    const [value, setValue] = React.useState("");
    const handleChange = (event) => setValue(event.target.value);
    const [list, setList] = React.useState();
    const [proof, setProof] = React.useState([]);

    const getAllowlistProof = (address) => {
        const hashedAddr = keccak256(address);
        const addrProof = ALLOWLIST_MERKLE_TREE.getHexProof(hashedAddr);

        return addrProof;
    };

    const getWaitlistProof = (address) => {
        const hashedAddr = keccak256(address);
        const addrProof = WAITLIST_MERKLE_TREE.getHexProof(hashedAddr);

        return addrProof;
    };

    const getTeamProof = (address) => {
        const hashedAddr = keccak256(address);
        const addrProof = TEAM_MERKLE_TREE.getHexProof(hashedAddr);

        return addrProof;
    };

    const merkleMap = {
        Team: getTeamProof,
        Allowlist: getAllowlistProof,
        Waitlist: getWaitlistProof,
    };

    const getAddressDetails = (addr) => {
        const hashedAddr = keccak256(addr);
        const onTeamList = TEAM_MERKLE_TREE.verify(TEAM_MERKLE_TREE.getHexProof(hashedAddr), hashedAddr, TEAM_MERKLE_TREE.getRoot().toString("hex"));
        const onWaitlist = WAITLIST_MERKLE_TREE.verify(WAITLIST_MERKLE_TREE.getHexProof(hashedAddr), hashedAddr, WAITLIST_MERKLE_TREE.getRoot().toString("hex"));
        const onAllowList = ALLOWLIST_MERKLE_TREE.verify(ALLOWLIST_MERKLE_TREE.getHexProof(hashedAddr), hashedAddr, ALLOWLIST_MERKLE_TREE.getRoot().toString("hex"));

        setList(onTeamList ? "Team" : onAllowList ? "Allowlist" : onWaitlist ? "Waitlist" : "Public");
        setProof(onTeamList ? getTeamProof(addr) : onAllowList ? getAllowlistProof(addr) : onWaitlist ? getWaitlistProof(addr) : "Don't need merkle proof for public mint.");

        console.log(list, proof);
    };
    React.useEffect(() => {
        if (value.length > 41 && value.length < 43 && value.startsWith("0x")) {
            getAddressDetails(value);
        }
    }, [value]);
    return (
        <Layout h="100vh">
            <Seo title="DEATH GIRL LICENSE | FREE MINT | NFT" />
            <Center>
                <OrderedList mt="24" mb="36" w="4xl">
                    <Heading ml="-10" mb="10">
                        Death Girl License
                    </Heading>
                    <ListItem>
                        <h4>Definitions.</h4>
                        “Art” means any art, design, and drawings that may be associated with an NFT that you Own. "NFT" means any blockchain-tracked, non-fungible token, such as those conforming to
                        the ERC-721 standard. “Own” means, with respect to an NFT, an NFT that you have purchased or otherwise rightfully acquired from a legitimate source, where proof of such
                        purchase is recorded on the relevant blockchain. “Extensions” means third party designs that: (i) are intended for use as extensions or overlays to the Art, (ii) do not modify
                        the underlying Art, and (iii) can be removed at any time without affecting the underlying Art. “Purchased NFT” means an NFT that you Own. “Third Party IP” means any third party
                        patent rights (including, without limitation, patent applications and disclosures), copyrights, trade secrets, trademarks, know-how or any other intellectual property rights
                        recognized in any country or jurisdiction in the world.
                    </ListItem>
                    <ListItem>
                        <h4>Ownership.</h4>
                        You acknowledge and agree that DEATH GIRL NFT (or, as applicable, its licensors) owns all legal right, title and interest in and to the Art, and all intellectual property
                        rights therein. The rights that you have in and to the Art are limited to those described in this License. DEATH GIRL NFT reserves all rights in and to the Art not expressly
                        granted to you in this License.
                    </ListItem>
                    <ListItem>
                        <h4>License.</h4>
                        a. General Use. Subject to your continued compliance with the terms of this License, DEATH GIRL NFT grants you a worldwide, non-exclusive, non-transferable, royalty-free
                        license to use, copy, and display the Art for your Purchased NFTs, along with any Extensions that you choose to create or use, solely for the following purposes: (i) for your
                        own personal, non-commercial use; (ii) as part of a marketplace that permits the purchase and sale of your NFTs, provided that the marketplace cryptographically verifies each
                        NFT owner’s rights to display the Art for their Purchased NFTs to ensure that only the actual owner can display the Art; or (iii) as part of a third party website or
                        application that permits the inclusion, involvement, or participation of your NFTs, provided that the website/application cryptographically verifies each NFT owner’s rights to
                        display the Art for their Purchased NFTs to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the
                        Purchased NFT leaves the website/application. b. Commercial Use. Subject to your continued compliance with the terms of this License, DEATH GIRL NFT grants you a limited,
                        worldwide, non-exclusive, non-transferable license to use, copy, and display the Art for your Purchased NFTs for the purpose of commercializing your own merchandise that
                        includes, contains, or consists of the Art for your Purchased NFTs (“Commercial Use”), provided that such Commercial Use does not result in you earning more than One Hundred
                        Thousand Dollars ($100,000) in gross revenue each year. For the sake of clarity, nothing in this Section 3.b will be deemed to restrict you from (i) owning or operating a
                        marketplace that permits the use and sale of NFTs generally, provided that the marketplace cryptographically verifies each NFT owner’s rights to display the Art for their
                        Purchased NFTs to ensure that only the actual owner can display the Art; (ii) owning or operating a third party website or application that permits the inclusion, involvement,
                        or participation of NFTs generally, provided that the third party website or application cryptographically verifies each NFT owner’s rights to display the Art for their
                        Purchased NFTs to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased NFT leaves the
                        website/application; or (iii) earning revenue from any of the foregoing, even where such revenue is in excess of $100,000 per year.
                    </ListItem>
                    <ListItem>
                        <h4>Restrictions.</h4>
                        You agree that you may not, nor permit any third party to do or attempt to do any of the foregoing without DEATH GIRL NFT’s express prior written consent in each case: (i)
                        modify the Art for your Purchased NFT in any way, including, without limitation, the shapes, designs, drawings, attributes, or color schemes (your use of Extensions will not
                        constitute a prohibited modification hereunder); (ii) use the Art for your Purchased NFTs to advertise, market, or sell any third party product or service; (iii) use the Art
                        for your Purchased NFTs in connection with images, videos, or other forms of media that depict hatred, intolerance, violence, cruelty, or anything else that could reasonably be
                        found to constitute hate speech or otherwise infringe upon the rights of others; (iv) use the Art for your Purchased NFTs in movies, videos, or any other forms of media, except
                        to the limited extent that such use is expressly permitted in Section 3(b) above or solely for your own personal, non-commercial use; (v) sell, distribute for commercial gain
                        (including, without limitation, giving away in the hopes of eventual commercial gain), or otherwise commercialize merchandise that includes, contains, or consists of the Art
                        for your Purchased NFTs, except as expressly permitted in Section 3(b) above; (vi) attempt to trademark, copyright, or otherwise acquire additional intellectual property rights
                        in or to the Art for your Purchased NFTs; or (vii) otherwise utilize the Art for your Purchased NFTs for your or any third party’s commercial benefit. To the extent that Art
                        associated with your Purchased NFTs contains Third Party IP (e.g., licensed intellectual property from a celebrity, athlete, or other public figure), you understand and agree
                        as follows: (w) that you will not have the right to use such Third Party IP in any way except as incorporated in the Art, and subject to the license and restrictions contained
                        herein; (x) that the Commercial Use license in Section 3(b) above will not apply; (y) that, depending on the nature of the license granted from the owner of the Third Party IP,
                        DEATH GIRL NFT may need to pass through additional restrictions on your ability to use the Art; and (z) to the extent that DEATH GIRL NFT informs you of such additional
                        restrictions in writing (email is permissible), you will be responsible for complying with all such restrictions from the date that you receive the notice, and that failure to
                        do so will be deemed a breach of this license. The restriction in Section 4 will survive the expiration or termination of this License.
                    </ListItem>
                    <ListItem>
                        <h4>Terms of License.</h4>
                        The license granted in Section 3 above applies only to the extent that you continue to Own the applicable Purchased NFT. If at any time you sell, trade, donate, give away,
                        transfer, or otherwise dispose of your Purchased NFT for any reason, the license granted in Section 3 will immediately expire with respect to those NFTs without the requirement
                        of notice, and you will have no further rights in or to the Art for those NFTs. If you exceed the $100,000 limitation on annual gross revenue set forth in Section 3.b above,
                        you will be in breach of this License, and must send an email to DEATH GIRL NFT at info@deathgirl.com within fifteen (15) days, with the phrase “NFT License - Commercial Use”
                        in the subject line, requesting a discussion with DEATH GIRL NFT regarding entering into a broader license agreement or obtaining an exemption (which may be granted or withheld
                        in DEATH GIRL NFT’s sole and absolute discretion). If you exceed the scope of the license grant in Section 3.b without entering into a broader license agreement with or
                        obtaining an exemption from DEATH GIRL NFT, you acknowledge and agree that: (i) you are in breach of this License; (ii) in addition to any remedies that may be available to
                        DEATH GIRL NFT at law or in equity, the DEATH GIRL NFT may immediately terminate this License, without the requirement of notice; and (iii) you will be responsible to reimburse
                        DEATH GIRL NFT for any costs and expenses incurred by DEATH GIRL NFT during the course of enforcing the terms of this License against you.
                    </ListItem>
                </OrderedList>
            </Center>
            <Box clear="left" mt={["24", "-24"]}>
                <Social />
            </Box>
        </Layout>
    );
};

export default LicensePage;
